import React from 'react';
import {
  GoogleLoginButton,
} from 'react-social-login-buttons';

const Login = () => {

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/login`;
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-header">
          <img src="logo.svg" alt="Logo" className="login-logo" />
          <h2>Welcome!</h2>
        </div>
        <div className="login-form">
            <GoogleLoginButton onClick={handleLogin}/>
        </div>
      </div>
    </div>
  );
};

export default Login;
