import React, { Component, createContext, createRef, useContext } from 'react';
import promptData from '../data/prompt.json';
import { ClientJS } from 'clientjs';
import MessageContext from '../context/MessageContext';
import CanvasContext from '../context/CanvasContext';
import { RotatingLines } from 'react-loader-spinner';

export const canvasContext = createContext();

export const useToken = () => useContext(CanvasContext);

const client = new ClientJS();
const fingerprint = client.getFingerprint().toString();

class CanvasProvider extends Component {
  static contextType = MessageContext; // Make sure this is set correctly

  constructor(props) {
    super(props);
    this.stageRef = createRef();
    this.state = {
      loading: false,
    };
  }

  sendQuestionWithImage = async (question, imageData, username, promptType) => {
    const { appendMessage } = this.context;
    const { token } = this.props;
    appendMessage(question, 'user');
    if (imageData === null && question.trim() === "") {
      appendMessage('No image given or question asked.', promptType);
      return;
    }
    const requestBody = {
        messages: [
          promptData[promptType],
          {
            "role": "user", "name": username, "content": [
              { "type": "text", "text": question },
              ...(imageData ? [{ "type": "image_url", "image_url": { "url": imageData } }] : [])
            ]
          }
        ],
        user: fingerprint
      };
      this.setState({ loading: true });
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/question`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(requestBody)
        });
        const data = await response.json();
        appendMessage(data.response, promptType);
      } catch (error) {
        console.error('Failed to send question with image:', error);
        appendMessage('Failed to retrieve response.', promptType);
      };
      this.setState({ loading: false });
  };

  captureCanvasAndSend = (question, user, promptType, stageRef) => {
    const { token } = this.props;
    if (!token) {
      console.error('No token available');
      return;
    }
    const stage = stageRef.current;
    const layer = stage.children[0];
    const hasContent = layer.children.some(child => {
      return (
        (child.className === 'Line' && child.attrs.points && child.attrs.points.length > 0) ||
        (child.className === 'Image' && child.attrs.image) ||
        (child.className === 'Text' && child.attrs.text)
      );
    });
    if (hasContent) {
      const imageData = stageRef.current.toDataURL({ pixelRatio: 2 });
      this.sendQuestionWithImage(question, imageData, user, promptType);
    } else {
      this.sendQuestionWithImage(question, null, user, promptType);
    }
    
  };

  render() {
    const { token } = this.props;
    return (
      <CanvasContext.Provider value={{
        token,
        stageRef: this.stageRef,
        captureCanvasAndSend: this.captureCanvasAndSend
      }}>
        {this.props.children}
        {this.state.loading && (
          <div className="chat-loader-overlay">
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="#0056b3"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
      </CanvasContext.Provider>
    );
  }
}


export default CanvasProvider;