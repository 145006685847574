import React, { useState, useRef, useEffect, useContext } from 'react';
import { Stage, Layer, Line, Image as KonvaImage } from 'react-konva';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faRedo, faEraser, faPencil, faBan,faFont, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Text } from 'react-konva';
import CanvasContext from '../context/CanvasContext';
import ReactGA from "react-ga4";

const CanvasArea = () => {
  const [strokeColor, setStrokeColor] = useState('#0056b3'); // Default color
  const [strokeWidth, setStrokeWidth] = useState(5); // Default stroke width  
  const [lines, setLines] = useState([]);
  const [operations, setOperations] = useState([]); // Track all operations for undo/redo
  const [redoOperations, setRedoOperations] = useState([]);
  const [mode, setMode] = useState('draw'); // 'draw' or 'erase'
  const [image, setImage] = useState(null); // For uploaded image
  const [texts, setTexts] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const isDrawing = useRef(false);

  const { stageRef } = useContext(CanvasContext);

  useEffect(() => {
    const handleResize = () => {
      // Handle resize logic here if necessary
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDragEnd = (index, e) => {
    const updatedTexts = texts.map((text, i) => {
      if (i === index) {
        return { ...text, x: e.target.x(), y: e.target.y() };
      }
      return text;
    });
    setTexts(updatedTexts);
  };

  const handleTextDblClick = (textIndex) => {
    const newTexts = texts.map((text, index) => {
      if (index === textIndex) {
        return { ...text, isEditing: true };
      }
      return text;
    });
    setTexts(newTexts);
  };

  const handleTextInputChange = (e) => {
    setCurrentText(e.target.value);
  };  
  
  // const handleTextEdit = (e, index) => {
  //   const newTexts = texts.map((text, idx) => {
  //     if (idx === index) {
  //       return { ...text, text: e.target.value };
  //     }
  //     return text;
  //   });
  //   setTexts(newTexts);
  // };

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    const newLine = { tool: mode, 
      points: [pos.x, pos.y], 
      color: strokeColor, // Use current stroke color
      width: strokeWidth // Use current stroke width
    };
    setLines([...lines, newLine]);
    setOperations([...operations, { type: 'line', object: newLine }]);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
  
    // Ensure there is at least one line
    if (lines.length === 0) {
      // Optionally, create a new line here if that fits your logic
      // lines.push({ points: [] });
      // or simply return to avoid errors
      return;
    }
  
    let lastLine = lines[lines.length - 1];
  
    // Check if lastLine exists and has a points array
    if (lastLine && Array.isArray(lastLine.points)) {
      lastLine.points = lastLine.points.concat([point.x, point.y]);
      setLines([...lines.slice(0, -1), lastLine]); // replace last line
    }
  };
  

  const handleMouseUp = () => {
    isDrawing.current = false;
  };
  
  const undo = () => {
    if (operations.length === 0) return;
  
    const lastOp = operations.pop();
    setOperations([...operations]); // Update operations array
  
    switch (lastOp.type) {
      case 'line':
        setLines(lines.slice(0, -1));
        break;
        case 'clear':
          // Restore lines, texts, and image from the last clear operation
          setLines(lastOp.object.lines);
          setTexts(lastOp.object.texts);
          setImage(lastOp.object.image);
          break;
      case 'upload':
        setImage(null);
        break;
      case 'addText':
        setTexts(texts.slice(0, -1)); // Remove the last added text
        break;
      default:
        break;
    }
    setRedoOperations([...redoOperations, lastOp]);
  };  

  const redo = () => {
    if (redoOperations.length === 0) return;
  
    const lastOp = redoOperations.shift();
    setRedoOperations([...redoOperations]);
  
    switch (lastOp.type) {
      case 'line':
        setLines([...lines, lastOp.object]);
        break;
      case 'clear':
        setLines([]); // Clear all lines
        setImage(null);
        setTexts([]); // Clear all texts
        break;
      case 'upload':
        setImage(lastOp.object);
        break;
      case 'addText':
        setTexts([...texts, lastOp.object]); // Re-add the text
        break;
      default:
        break;
    }
    setOperations([...operations, lastOp]);
  };  

  const clear = () => {
    // Record the current state before clearing
    setOperations([...operations, {
        type: 'clear',
        object: {
            lines: [...lines],
            texts: [...texts],
            image: image
        }
    }]);
    setLines([]);  // Clear all lines
    setTexts([]);  // Clear all texts
    setImage(null);  // Clear the image
};


  const upload = () => {
    ReactGA.event({
      category: 'Button', // Optional category
      action: 'Click',  // Required action
      label: 'Upload Button Clicked', // Optional label
    });
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new window.Image();
          img.src = event.target.result;
          img.onload = () => {
            setImage({
              image: img,
              x: 0,
              y: 0,
              width: stageRef.current.width(),
              height: stageRef.current.height(),
            });
            setOperations([...operations, { type: 'upload', object: img }]);
          };
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

    // Set the border for the Konva canvas stage
    useEffect(() => {
        const resizeCanvas = () => {
          // Update state with new width and height
        };
        window.addEventListener('resize', resizeCanvas);
        return () => {
          window.removeEventListener('resize', resizeCanvas);
        };
      }, []);

      const addText = () => {
        const newText = {
          text: currentText, // Use the text from the state
          x: 50, // Default position
          y: 50,
          fontSize: 20,
          color: strokeColor, // Use current stroke color or a specific one for text
          isEditing: false,
        };
        setTexts([...texts, newText]);
        setOperations([...operations, { type: 'addText', object: newText }]);
        setCurrentText(''); // Clear the current text input after adding
      };
      

  return (
        <div className="canvas-container">
        <Stage id="drawing-board"
            width={800}
            height={600}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            ref={stageRef}
          >
            <Layer>
            {image && <KonvaImage image={image.image}
                  x={image.x}
                  y={image.y}
                  width={image.width}
                  height={image.height} />}
              {lines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke={line.tool === 'erase' ? 'white' : line.color}
                  strokeWidth={line.width}
                  tension={0.5}
                  lineCap="round"
                  globalCompositeOperation={line.tool === 'erase' ? 'destination-out' : 'source-over'}
                />
              ))}
              {texts.map((text, i) => (
              <Text
              key={i}
              text={text.text}
              x={text.x}
              y={text.y}
              fontSize={text.fontSize}
              fill={text.color}
              draggable
              onDblClick={() => handleTextDblClick(i)}
              onDragEnd={(e) => handleDragEnd(i, e)}
              />
              ))}
            </Layer>
          </Stage>
          <div id="canvas-controls" className="canvas-controls">
          <div className="button-container">
            <button onClick={undo}>
              <FontAwesomeIcon icon={faUndo} />
            </button>
            <div className="tooltip">Undo</div>
          </div>
          <div className="button-container">
            <button onClick={redo}>
              <FontAwesomeIcon icon={faRedo} />
            </button>
            <div className="tooltip">Redo</div>
          </div>
          <div className="button-container">
            <button onClick={() => setMode('draw')}>
              <FontAwesomeIcon icon={faPencil} />
            </button>
            <div className="tooltip">Draw</div>
          </div>
          <div className="button-container">
            <button onClick={() => setMode('erase')}>
              <FontAwesomeIcon icon={faEraser} />
            </button>
            <div className="tooltip">Erase</div>
          </div>
          <div className="button-container">
            <button onClick={clear}>
              <FontAwesomeIcon icon={faBan} />
            </button>
            <div className="tooltip">Clear</div>
          </div>
          <div className="button-container">
            <input
              type="color"
              value={strokeColor}
              onChange={(e) => setStrokeColor(e.target.value)}
            />
            <div className="tooltip">Pencil Color</div>
          </div>
          <div className="button-container">
            <input
              type="range"
              min="1"
              step="2"
              max="21"
              value={strokeWidth}
              onChange={(e) => setStrokeWidth(+e.target.value)}
            />
            <div className="tooltip">Pencil Width</div>
          </div>
          <div className="button-container">
            <input
              type="text"
              value={currentText}
              onChange={handleTextInputChange}
              placeholder="Enter text"
            />
            <div className="tooltip">Text Input</div>
          </div>
          <div className="button-container">
            <button onClick={addText}>
              <FontAwesomeIcon icon={faFont} />
            </button>
            <div className="tooltip">Add Text</div>
          </div>
          <div className="button-container">
            <button onClick={upload}>
              <FontAwesomeIcon icon={faUpload} />
            </button>
            <div className="tooltip">Upload Image</div>
          </div>
        </div>
      </div>
  );
};

export default CanvasArea;
