import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import { ReactComponent as CustomIcon } from '../../src/logo.svg';

function Header({ user, onLogout }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <CustomIcon style={{ marginRight: 8 }} /> {/* Adjust style as needed */}
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          Education e-Board
        </Typography>
        {user && (<IconButton color="inherit">
          <Settings />
        </IconButton>)}
        {user && (
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <Avatar alt={user.name} src={user.picture} />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>Name: {user?.name}</MenuItem>
          <MenuItem onClick={handleMenuClose}>Email: {user?.email}</MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); onLogout(); }}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
