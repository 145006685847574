// MessageProvider.js
import React, { Component } from 'react';
import MessageContext from '../context/MessageContext';  // Make sure this path is correct

export default class MessageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: []
        };
    }

    appendMessage = (message, sender) => {
        this.setState(prevState => ({
            messages: [...prevState.messages, { content: message, sender }]
        }));
    };

    render() {
        // Ensure you're passing `this.state.messages` not `this.messages`
        return (
            <MessageContext.Provider value={{ messages: this.state.messages, appendMessage: this.appendMessage }}>
                {this.props.children}
            </MessageContext.Provider>
        );
    }
}
