import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { ReactComponent as CustomIcon } from '../../src/logo.svg';

function Footer() {
  return (
    <AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
      <CustomIcon style={{ marginRight: 8 }} /> {/* Adjust style as needed */}
        <Typography variant="body1" color="inherit" component="div" sx={{ flexGrow: 1 }}>
          © 2024 Education e-Board
        </Typography>
        <Typography variant="body2" color="inherit">
          All rights reserved by <a target='_blank'
            rel='noopener noreferrer' href="https://jaykmr.com">Jayanth Kumar</a>. 
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
