import React, { Component, createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faCalculator} from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';
import CanvasContext from '../context/CanvasContext';
import Chat from './Chat';
class ChatArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: ''
    };
    this.userInputRef = createRef();
  }

  handleInputChange = (event) => {
    this.setState({ input: event.target.value });
  };

  handleAsk = () => {
    ReactGA.event({
      category: 'Button', // Optional category
      action: 'Click',  // Required action
      label: 'Ask Button Clicked', // Optional label
      value: 5, // optional, must be a number
    });
    const question = this.userInputRef.current?.value;
    if (this.context.captureCanvasAndSend) {
      this.context.captureCanvasAndSend(question, "user", "Tutor", this.context.stageRef);
    }
  };

  handleSolve = () => {
    ReactGA.event({
      category: 'Button', // Optional category
      action: 'Click',  // Required action
      label: 'Solve Button Clicked', // Optional label
      value: 5, // optional, must be a number
    });
    const question = this.userInputRef.current?.value || "Please solve the question directly.";
    if (this.context.captureCanvasAndSend) {
      this.context.captureCanvasAndSend(question, "user", 'Solver', this.context.stageRef);
    }
  };

  handleStop = () => {
    // Integrate stop functionality
  };

  render() {
    return (
      <div id="chat" className="chat">
        <Chat />
        <div id="chat-inputs" className="chat-inputs">
          <div id="chat-buttons" className="chat-buttons">
            <textarea id="userInput" type="text" ref={this.userInputRef} value={this.state.input} onChange={this.handleInputChange} placeholder="Ask a question..." />
            <div className="button-container">
            <button onClick={this.handleAsk} title="Ask a question"><FontAwesomeIcon icon={faQuestionCircle} /></button>
            <div className="tooltip">Ask</div>
            </div>
            <div className="button-container">
            <button onClick={this.handleSolve} title="Solve the problem"><FontAwesomeIcon icon={faCalculator} /></button>
            <div className="tooltip">Solve</div>
            </div>
            {/* <button onClick={this.handleStop}><FontAwesomeIcon icon={faStop} /></button> */}
          </div>
        </div>
      </div>
    );
  }
}

// Setting the contextType lets us use this.context to access the context
ChatArea.contextType = CanvasContext;

export default ChatArea;
