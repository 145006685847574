// Chat.js
import React, { useContext, useRef, useEffect } from 'react';
import { MathJax } from 'better-react-mathjax';
import MessageContext from '../context/MessageContext';  // Correct the import path if necessary

const Chat = () => {
    const { messages = [] } = useContext(MessageContext);
    const chatEndRef = useRef(null);

    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);  // Scrolls to bottom every time messages update

    return (
        <div id="chat-messages" style={{ height: '600px', overflowY: 'auto', position: 'relative'  }}>
            {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                    <strong>{message.sender === 'user' ? 'You' : message.sender}:</strong>
                    <MathJax>{message.content}</MathJax>
                </div>
            ))}
            <div ref={chatEndRef} />
        </div>
    );
};

export default Chat;
