import React, { useState, useEffect, useCallback } from 'react';
import { MathJaxContext } from 'better-react-mathjax';
import Login from './components/Login';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import CanvasArea from './components/CanvasArea';
import ChatArea from './components/ChatArea';
import CanvasProvider from './providers/CanvasProvider';
import MessageProvider from './providers/MessageProvider';
import { useNavigate } from 'react-router-dom';

function App() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/logout`, {
      method: 'GET',
      credentials: 'include',
    })
    .then(() => {
      setUser(null);
      setToken(null);
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      navigate('/login'); // Navigate to login page after logout
    })
    .catch((error) => console.error('Error logging out:', error));
  }, [navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userProfile = {
      user_id: urlParams.get('user_id'),
      email: urlParams.get('email'),
      name: urlParams.get('name'),
      picture: urlParams.get('picture'),
    };
    const tokenParam = urlParams.get('token');

    if (userProfile.email && tokenParam) {
      localStorage.setItem('user', JSON.stringify(userProfile));
      localStorage.setItem('token', tokenParam);
      setUser(userProfile);
      setToken(tokenParam);
    } else {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      const storedToken = localStorage.getItem('token');
      if (storedUser && storedToken) {
        setUser(storedUser);
        setToken(storedToken);
      } else {
        navigate('/login'); // Navigate to login page if no valid token
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (token) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/validateToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        if (!data.valid) {
          handleLogout(); // Token is invalid or expired, logout the user
        }
      })
      .catch(() => handleLogout());
    }
  }, [token, handleLogout]);

  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    }
  };

  return (
    <MathJaxContext version={3} config={config}>
      <div className="App">
        <Header user={user} onLogout={handleLogout} />
        {user ? (
          <MessageProvider>
            <CanvasProvider token={token}>
              <div className="main-content">
                <CanvasArea />
                <ChatArea />
              </div>
            </CanvasProvider>
          </MessageProvider>
        ) : (
          <Login />
        )}
        <Footer />
      </div>
    </MathJaxContext>
  );
}

export default App;
